<template>
  <div class="menu" :class="$mq">
    <div class="menu__item behance">
      <a :href="behance.url" target="_blank">
        <img :src="behance.logo" alt="behance">
      </a>
    </div>
    <div class="menu__item email">
      <a :href="'mailto:' + email.address">
        <img :src="email.img" :alt="email.address">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  props: ['activeTab'],
  data: () => {
    return {
      behance: {
        url: 'https://www.behance.net/ekaterrinae8cc',
        logo: require('@/assets/images/Behance_logo.png')
      },
      email: {
        address: 'ekaterrina@inbox.ru',
        img: require('@/assets/images/mail.png')
      }
    }
  }
}
</script>

<style scoped>
.menu {
  width: 100vw;
  height: 100px;
  vertical-align: center;
}

.menu__item {
  height: inherit;
  float: right;
  margin-left: 30px;
}

.menu__item.email img {
  height: 35px;
  float: right;
  margin-top: 27px;
}

.menu__item.behance {
  margin-right: 5%;
}

.menu__item.behance img {
  height: 25px;
  float: right;
  margin-top: 30px;
}

/* style for mobile phones */
.menu.mobile .menu__item.email img {
  height: 25px;
}

.menu.mobile .menu__item.instagram img {
  height: 30px;
}
.menu.mobile .menu__item.behance img {
  height: 15px;
}
</style>