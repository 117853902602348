<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@import "/assets/fonts/PTRobotUIWeb/regular/PTRootUI_Regular.css";
@import "/assets/fonts/PTRobotUIWeb/bold/PTRootUI_Bold.css";
@import "/assets/fonts/PTRobotUIWeb/light/PTRootUI_Light.css";

body {
  margin: 0;
}

#app {
  font-family: PTRootUIWebRegular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
