<template>
  <div class="main-page" :class="$mq">
    <Menu active-tab="main"/>
    <div class="main-page__content">
      <h1 class="outline-text">Ekaterina Ryazantseva</h1>
      <h2>interior visualization</h2>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu";

export default {
  name: 'MainPage',
  components: {Menu},
  data: () => {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-page {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: url("../assets/images/3.jpg") no-repeat left;
  background-size: 35vw;
}

.main-page__content {
  position: absolute;
  top: 40%;
  right: 5%;
  text-align: right;
  width: 100vw;
}

.outline-text {
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 1px black;
}

h1 {
  font-family: PTRootUIWebBold, sans-serif;
  font-size: 100px;
  margin: 0;
}

h2 {
  font-family: PTRootUIWebLight, sans-serif;
  font-size: 30px;
  margin: 0;
}

/* style for mobile devices */
.main-page.laptop h1 {
  font-size: 55px;
}

.main-page.tablet h1 {
  font-size: 50px;
}

.main-page.mobile h1 {
  font-size: 30px;
}

.main-page.laptop h2 {
  font-size: 17px;
}

.main-page.tablet h2 {
  font-size: 15px;
}

.main-page.mobile h2 {
  font-size: 10px;
}
.main-page.mobile .main-page__content {
  top: 20%;
}
.main-page.tablet .main-page__content {
  top: 20%;
}
.main-page.mobile {
  background-position: 100% 100%;
  background-size: 70vw;
}
.main-page.tablet {
  background-position: 100% 100%;
  background-size: 60vw;
}
</style>
